import { KeyValPair } from '@models/Common'
import apiGateway from '@utils/axiosInterceptor'
import { GroupType } from './model/group.model'
import { UserInfo } from './model/user.model'
import axios from 'axios'

const apiToken = process.env.REACT_APP_API_KEY;

const GetUserInfo = async (email: string) => {
  return await apiGateway.get<UserInfo>(`/api/v1/users/info?email=${email}`).then((rep) => {
    return rep.data
  })
}

const CreateUser = async (params: any) => {
  try {
    const response = await apiGateway.post<UserInfo>('/api/v1/users/create', params);
    return response.data;
  } catch (error: any) {
    console.error('Create user error:', error.response?.data || error);
    throw error;
  }
}

const RegisterUser = async (organizationId: any) => {
  return await apiGateway.get<UserInfo>(`/api/v1/projects/getProjectByOrganizationId/${organizationId}`).then((rep) => {
    return rep.data
  })
}

const GetRoleMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/roles/meta').then((rep) => {
    return rep.data
  })
}

const GetUserMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/users/meta').then((rep) => {
    return rep.data
  })
}

const GetGroupTypeList = async () => {
  return await apiGateway.get<GroupType[]>('/api/v1/groups/meta').then((rep) => {
    return rep.data
  })
}

const GetUserFormRights = async (userId: string, cat?: string) => {
  return await apiGateway
    .get<UserInfo>(`/api/v1/users/${userId}/form-rights?form_cat=${cat || ''}`)
    .then((rep) => {
      return rep.data
    })
}
const GetOrganizationMetaList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/organizations/meta').then((rep) => {
    return rep.data
  })
}

const GetLimitList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/organizations/limitedList').then((rep) => {
    return rep.data
  })
}

const GetAllRoleList = async () => {
  return await apiGateway.get<KeyValPair[]>('/api/v1/role/getAllRoleList').then((rep) => {
    return rep.data
  })
}

const checkOktaUser = async (email: string) => {
  try {
    const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
    const response = await axios.get(`${ciamBaseUrl}/api/v1/users/${email}`, {
      headers: {
        'Authorization': `SSWS ${apiToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return null;
    }
    throw error;
  }
}

const oktaReg = async (params: any) => { 
  try {
    const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
    
    // 检查用户是否存在
    const existingUser = await checkOktaUser(params.profile.email);
    
    if (existingUser) {
      // 验证用户信息是否匹配
      if (existingUser.profile.firstName !== params.profile.firstName || 
          existingUser.profile.lastName !== params.profile.lastName) {
        throw new Error('User already exists with different personal information');
      }
      // 如果信息匹配，直接返回现有用户
      return existingUser;
    }

    // 如果用户不存在，创建新用户
    const response = await axios.post<UserInfo>(`${ciamBaseUrl}/api/v1/users`, params, {
      headers: {
        'Authorization': `SSWS ${apiToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const oktaAuthorization = async (appId: string, params: any) => {
  const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
  const url = `${ciamBaseUrl}/api/v1/apps/${appId}/users`;
  
  try {
    const response = await axios.post<UserInfo>(url, params, {
      headers: {
        'Authorization': `SSWS ${apiToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

// const oktaDeleteUser = async (userId: string, appId: string) => {
// 
//   const url = `/api/v1/apps/${appId}/users/${userId}`;
//   return await axios.delete(url, {
//     headers: {
//       'Authorization': `SSWS ${apiToken}`
//     }
//   }).then((rep) => {
//     return rep.data;
//   })
// }

const deleteUser = async (userId: string) => {
  const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
  const url = `${ciamBaseUrl}/api/v1/users/${userId}`;

  try {
    const response = await axios.delete(url, {
      headers: {
        'Authorization': `SSWS ${apiToken}`
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const assignUserToApp = async (appId: string, userId: string) => {
  const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
  const url = `${ciamBaseUrl}/api/v1/apps/${appId}/users/${userId}`;

  try {
    const response = await axios.put(url, {}, {
      headers: {
        'Authorization': `SSWS ${apiToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const addUserToGroup = async (groupId: string, userId: string) => {
  const ciamBaseUrl = process.env.REACT_APP_API_CIAM_BASE || 'https://onepass-sso-uat.cic.hk';
  const url = `${ciamBaseUrl}/api/v1/groups/${groupId}/users/${userId}`;

  try {
    const response = await axios.put(url, {}, {
      headers: {
        'Authorization': `SSWS ${apiToken}`,
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
}

const UserService = {
  GetUserInfo,
  GetRoleMetaList,
  GetUserMetaList,
  GetOrganizationMetaList,
  GetGroupTypeList,
  GetUserFormRights,
  RegisterUser,
  GetLimitList,
  GetAllRoleList,
  CreateUser,
  oktaReg,
  oktaAuthorization,
  assignUserToApp,
  addUserToGroup,
  deleteUser,
  checkOktaUser
}

export default UserService

import { Box, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import cicLogoutLogo from '../../assets/img/cic-logout-logo.png';

const FooterContainer = styled('div')(() => ({
  width: '100%',
  height: '106px',
  backgroundColor: '#F4FBFA',
  position: 'fixed',
  bottom: 0,
  left: 0
}));

const FooterContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px 60px',
  maxWidth: '100%',
  margin: '0 auto',
  gap: '8px',
  height: '100%',
  justifyContent: 'center'
}));

const TopRow = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between'
}));

const LeftGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '32px'
}));

const BottomRow = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '20px'
}));

const StyledLink = styled(Link)(() => ({
  color: '#333',
  textDecoration: 'none',
  fontWeight: 'bold',
  fontSize: '14px',
  '&:hover': {
    color: '#199F56'
  }
}));

const Copyright = styled('span')(() => ({
  color: '#666',
  fontSize: '14px',
  opacity: 0.8,
  marginTop: '-20px'

}));

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <TopRow>
          <LeftGroup>
            <Box
              component="img"
              alt="CIC Logo"
              src={cicLogoutLogo}
              sx={{ width: '150px', padding: '20px' }}
            />
            <StyledLink to="#">Terms & Conditions</StyledLink>
            <StyledLink to="#">Privacy Policy</StyledLink>
            <StyledLink to="#">Disclaimer</StyledLink>
          </LeftGroup>
          <StyledLink to="#">Contact Us</StyledLink>
        </TopRow>
        <BottomRow>
          <Copyright>
            Copyright © 2024 Material Exchange. All Rights Reserved.
          </Copyright>
        </BottomRow>
      </FooterContent>
    </FooterContainer>
  );
};

export default Footer;

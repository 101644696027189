import { Box, styled } from '@mui/material';
import cicLogoutLogo from '../../assets/img/cic-logout-logo.png';
import toplogo from '../../assets/img/top-logo.png';

const HeaderContainer = styled('div')(() => ({
  width: '100%',
  height: '74px',
  backgroundColor: '#fff',
  display: 'flex',
  alignItems: 'center',
  padding: '0 60px',
  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 1000
}));

const LogoGroup = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  gap: '40px'
}));

const NavGroup = styled('div')(() => ({
  display: 'flex',
  marginLeft: 'auto',
  gap: '32px'
}));

const Header = () => {
  return (
    <HeaderContainer>
      <LogoGroup>
        <Box
          component="img"
          alt="CIC Logo"
          src={cicLogoutLogo}
          sx={{ width: '150px', padding: '20px' }}
        />
        <Box
          component="img"
          alt="Smart Waste Management Tool"
          src={toplogo}
          sx={{ width: '250px', paddingTop: '10px' }}
        />
      </LogoGroup>
      <NavGroup>
        {/* 导航链接可以在这里添加 */}
      </NavGroup>
    </HeaderContainer>
  );
};

export default Header;
